import React, { useEffect } from "react";
import "./Terms.css";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className="termsContainer">
        <div className="termstext">
          <div className="heading">Terms and Conditions for AIGPTZero</div>
          <br></br>

          <p className="para">
            Welcome to AIGPTZero, an AI-powered GPT (Generative Pre-trained
            Transformer) detector that helps to detect and analyze the content
            generated by the users using our services.
          </p>
          <br></br>
          <p className="para">
            These Terms and Conditions govern your use of AIGPTZero, its
            website, and all its tools, services, and features available on or
            through the website. By accessing and using our services, you agree
            to be bound by these Terms and Conditions, as well as our Privacy
            Policy. If you do not agree to these terms, please do not use our
            services.
          </p>
          <br></br>
          <h2>Use of Our Services</h2>
          <br></br>
          <p className="para">
            AIGPTZero provides users with an AI-powered GPT detector, which can
            be used to detect and analyze the content generated by users. By
            using our services, you agree to use them only for lawful purposes
            and in compliance with all applicable laws and regulations.
          </p>
          <br></br>
          <h2>Intellectual Property Rights</h2>
          <br></br>
          <p className="para">
            All text, graphics, user interfaces, visual interfaces, photographs,
            trademarks, logos, sounds, music, artwork, and computer code
            (collectively, "Content"), including but not limited to the design,
            structure, selection, coordination, expression, "look and feel" and
            arrangement of such Content, contained on the website is owned,
            controlled or licensed by or to AIGPTZero, and is protected by trade
            dress, copyright, patent and trademark laws, and various other
            intellectual property rights.
          </p>
          <br></br>
          <h2> Privacy Policy</h2>
          <br></br>
          <p className="para">
            Our Privacy Policy explains how we collect, use, and disclose
            information from and about you when you use our services. By using
            our services, you consent to the collection, use, and disclosure of
            your information as described in our Privacy Policy.
          </p>
          <br></br>
          <h2> Disclaimer of Warranties</h2>
          <br></br>
          <p className="para">
            AIGPTZero provides its services "as is" and without any warranty or
            condition, express, implied, or statutory. We do not warrant that
            our services will be uninterrupted, error-free, or free of viruses
            or other harmful components. We also do not warrant that the results
            obtained from the use of our services will be accurate or reliable.
          </p>
          <br></br>
          <h2>Limitation of Liability</h2>
          <br></br>
          <p className="para">
            In no event shall AIGPTZero, its affiliates, or their respective
            directors, officers, employees, agents, partners, or licensors be
            liable for any indirect, incidental, special, consequential or
            punitive damages, including without limitation, loss of profits,
            data, use, goodwill, or other intangible losses, arising out of or
            in connection with your use of our services.
          </p>
          <br></br>
          <h2>Indemnification</h2>
          <br></br>
          <p className="para">
            You agree to indemnify and hold harmless AIGPTZero, its affiliates,
            and their respective directors, officers, employees, agents,
            partners, and licensors from and against any and all claims,
            damages, obligations, losses, liabilities, costs or debt, and
            expenses (including but not limited to reasonable attorney's fees)
            arising from your use of our services or your violation of these
            Terms and Conditions.
          </p>
          <br></br>
          <h2> Modification of Terms</h2>
          <br></br>
          <p className="para">
            We reserve the right to modify these Terms and Conditions at any
            time. Your continued use of our services after such modifications
            will constitute your acknowledgment of the modified Terms and
            Conditions and agreement to abide and be bound by the modified Terms
            and Conditions.
          </p>
          <br></br>
          <h2>Termination</h2>
          <br></br>
          <p className="para">
            We may terminate or suspend your access to our services immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach these Terms and
            Conditions.
          </p>
          <br></br>
          <h2>Governing Law</h2>
          <br></br>
          <p className="para">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of California, without giving effect to any
            principles of conflicts of law, without giving effect to any
            principles of conflicts of law.
          </p>
          <br></br>
          <h2>Entire Agreement</h2>
          <br></br>
          <p className="para">
            These Terms and Conditions, together with our{" "}
            <Link to="/privacy">Privacy Policy</Link>, constitute the entire
            agreement between you and AIGPTZero regarding the use of our
            services.
          </p>
          <br></br>
          <p className="para">
            If you have any questions or concerns about our Terms and Conditions
            or Privacy Policy, please contact us at{" "}
            <a href="mailto:info@aigptzero.com">info@aigptzero.com</a>.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Terms;
