import React from "react";
import "./Second.css";
function Second() {
  return (
    <div className="secondContainer">
      <div className="imageSection">
        <img src="https://i.imgur.com/9axONhP.png" className="image" />
      </div>
      <div className="textSection">
        <div className="heading">What is AI detector</div>
        <br></br>
        <p className = "para">
          Our AI detection model includes several components that analyze text
          to determine its origin and if it was written by AI. We use a
          multi-stage methodology designed to optimize accuracy while minimizing
          false positives and negatives. From the macro level to the micro one,
          this is how DeepAnalyse™ Technology works. <br></br>Our model specializes in
          identifying AI generated content like Chat GPT, GPT 3, GPT 4, Bard,
          LLaMa models … Finally, we employ a comprehensive deep learning
          methodology, trained on extensive text collections from the internet,
          educational datasets, and our proprietary synthetic AI datasets
          produced using various language models.
        </p>
      </div>
    </div>
  );
}

export default Second;
