import React, { useState } from "react";
import "./Faq.css";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";

function Faq() {
  const faqData = [
    {
      question: "How Does AIGPTZero work?",
      answer:
        "AIGPTZero utilizes state-of-the-art natural language processing algorithms to analyze text content. It processes the input text and compares it to a vast database of existing content to identify similarities, plagiarism, and content quality. This advanced AI technology ensures accurate and comprehensive content detection.",
    },
    {
      question: "What is the accuracy rate of AIGPTZero?",
      answer:
        "AIGPTZero boasts an impressive accuracy rate, consistently achieving over 95% accuracy in content detection. Our advanced algorithms and constant improvement efforts ensure that you can rely on precise and trustworthy results when using AIGPTZero.",
    },
    {
      question: "Who can take Benefits from AIGPTZero's AI content detector?",
      answer:
        "AIGPTZero is a versatile tool suitable for a wide range of users. It's beneficial for writers, students, researchers, bloggers, businesses, and content creators looking to ensure the originality and quality of their content. Whether you're an individual or part of an organization, AIGPTZero can help you maintain content integrity.",
    },
    {
      question:
        "Will my text get plagiarized or be available online, If I check it on AIGPTZero?",
      answer:
        "Rest assured that your text remains confidential when checked on AIGPTZero. We prioritize user privacy and data security. AIGPTZero does not store or share the content you submit. It's designed to help you identify potential issues and improve your content, not to distribute it.",
    },

    {
      question: "Does AIGPTZero work with different languages? ",
      answer:
        "Yes, AIGPTZero is designed to work with a wide variety of languages. Our AI model has been trained on a diverse set of texts, allowing it to detect content issues and plagiarism in multiple languages. Whether your content is in English, Spanish, French, or any other language, AIGPTZero can help you maintain its integrity.",
    },

    // Add more FAQ items as needed
  ];

  const [selected, setSelected] = useState(null);

  const toggle = (index) => {
    if (selected == index) {
      setSelected(null); // If the question is already selected, hide the answer
    } else {
      setSelected(index); // If the question is not selected, show the answer
    }
  };

  return (
    <div className="faq">
      <div className="accordion">
        {faqData.map((item, index) => (
          <div key={index} className="item">
            <div className="title" onClick={() => toggle(index)}>
              <h3>{item.question}</h3>
              <span>
                {selected == index ? <IoIosArrowDown /> : <IoIosArrowBack />}
              </span>
            </div>
            <div className={selected == index ? ".content .show" : "content"}>
              <div className="content-text"> {item.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
