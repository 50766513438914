import "./App.css";
import Gpt from "./Components/Engine/Engine.js";
import Second from "./Components/Second/Second.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar.js";
import Footer from "./Components/Footer/Footer.js";
import Privacy from "./Components/Privacy/Privacy.js";
import Home from "./Components/Home/Home.js";
import Terms from "./Components/Terms/Terms.js"
function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
