import React, { useEffect } from "react";
import Gpt from "../Engine/Engine.js";
import Second from "../Second/Second.js";
import Faq from "../Faq/Faq.js";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <React.Fragment>
    
      <div>
        <Gpt />
        <Second />
        <Faq />
      </div>
      </React.Fragment>
    </>
  );
}
export default Home;
