import React from "react";
import "./Footer.css";
import {
  RiTwitterXFill,
  RiFacebookBoxLine,
  RiLinkedinBoxLine,
  RiPinterestLine,
} from "react-icons/ri";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
function Footer() {
  const iconStyle = {
    fontSize: "24px", // Set your desired size here
  };
  return (
    <div className="footer-container">
      <div className="footer-text">
        <div className="section-one">
          {" "}
          <Link to="/">
            <img src="https://i.imgur.com/zz9lBP5.png" className="flogo" />
          </Link>
          {/* <div className="social-icons">
            <RiTwitterXFill className="icon" />
            <RiFacebookBoxLine className="icon" />
            <RiLinkedinBoxLine className="icon" />
            <RiPinterestLine className="icon" />
          </div>
          <div className="copyright">2023 Copyright © AIGPTZero.com</div> */}
        </div>
        <div className="section-two">
          <div className="st-text">About</div>
          <Link to="/privacy " className="st-subtext">
            Privacy Policy
          </Link>
          <Link to="/terms" className="st-subtext">
            Terms and Condition
          </Link>
        </div>

        <div className="section-three">
          <div className="st-text">Features</div>
          <div className="st-subtext">AI Detector</div>
          <div className="st-subtext">Word Counter</div>
        </div>
        <div className="section-four">
          <div className="st-text">Contact</div>
          <div className="st-subtext2">
            You can send email to us 
          </div>
          <a href="mailto:info@aigptzero.com"> info@aigptzero.com</a>
          <div className="social-icons">
            <RiTwitterXFill className="icon" />
            <RiFacebookBoxLine className="icon" />
            <RiLinkedinBoxLine className="icon" />
            <RiPinterestLine className="icon" />
          </div>
          <div className="copyright">2023 Copyright © AIGPTZero.com</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
