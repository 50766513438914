function GradientSVG() {
  const idCSS = "hello";
  const gradientTransform = `rotate(90)`;

  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform} >
        <stop offset="0%" stopColor="#33ff57" />
          <stop offset="50%" stopColor="yellow" />
          <stop offset="100%" stopColor="#ff5733" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default GradientSVG;
