import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <Link to="/"><img src="https://i.imgur.com/dhh6Iej.png" className="logo" /></Link>
      <div>&nbsp;</div>
      <nav ref={navRef}>
        <Link to="/">Home</Link>
        <a href="#">Price</a>
        <a href="#">Blog</a>
        <a href="#">About us</a>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
