import React, { useEffect } from "react";
import "./Privacy.css";
function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className="privacyContainer">
        <div className="ptextSection">
          <div className="heading">Privacy Policy for AI GPT Zero</div>
          <br></br>
          <p className="para">
            At AIGptzero, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy explains how we collect, use, disclose, and protect your data
            when you use our AI Content Detector, Content Rephrase, Grammar
            Checker, and Word Counter services through the AIGptzero website at
            https://aizerogpt.com. Using our Services, you consent to the
            practices described in this Privacy Policy.
          </p>
          <br></br>
          <h2>Information We Collect</h2>
          <br></br>
          <p className="para">
            We may collect and process personal information that you provide to
            us, including but not limited to:
          </p>
          <br></br>
          <li>Name</li>
          <li>Email address</li>
          <li>Username and password</li>
          <li>Contact information</li>
          <li>Payment information (if applicable)</li>
          <br></br>
          <h3>User Content</h3>
          <br></br>
          <p className="para">
            We collect the content you submit, upload, or provide using our
            Services, including text, images, videos, and any other data you
            submit to or generate within our Services. We may also collect
            information about your device, such as your IP address and browser
            type.
          </p>
          <br></br>
          <h2>How We Use Your Information</h2>
          <br></br>
          <p className="para">
            We use your information for the following purposes:
          </p>
          <br></br>
          <li>To Provide and Improve Our Services:</li>
          <p>
            We use your personal information and user content to deliver,
            maintain, and enhance the quality of our Services. This includes
            analyzing your content to detect and identify potentially harmful or
            inappropriate material.
          </p>
          <br></br>
          <li>Communication:</li>
          <p>
            We may use your contact information to respond to your inquiries,
            provide customer support, and send you updates or alerts related to
            our Services.
          </p>
          <br></br>
          <li>Personalization:</li>
          <p>
            We may use your data to personalize your experience on the Site and
            tailor content and recommendations to your preferences.
          </p>
          <br></br>
          <li>Compliance and Legal Obligations:</li>
          <p>
            We may use your information to comply with legal and regulatory
            requirements, respond to lawful requests, or protect our rights or
            the rights of others.
          </p>
          <br></br>
          <h2>Data Disclosure</h2>
          <br></br>
          <p className="para">
            We may share your information with third parties under the following
            circumstances:
          </p>
          <br></br>
          <h3>Service Providers</h3>
          <p className="para">
            We may engage third-party service providers to assist with the
            provision of our Services. These providers are contractually bound
            to protect your data and only use it for the purpose of providing
            the services we have contracted them for.
          </p>
          <br></br>

          <h3> Legal Requirements</h3>
          <p className="para">
            We may disclose your information in response to legal requests,
            court orders, or other applicable laws or regulations.
          </p>
          <br></br>

          <h3>Business Transfers</h3>
          <p className="para">
            If AIGptzero undergoes a merger, acquisition, or sale of all or a
            portion of its assets, your data may be transferred as part of the
            transaction.
          </p>
          <br></br>
          <h2>Data Security</h2>
          <br></br>
          <p className="para">
            We take reasonable measures to protect your data from unauthorized
            access, disclosure, alteration, and destruction. However, no data
            transmission over the internet or method of electronic storage is
            entirely secure, and we cannot guarantee absolute security.
          </p>
          <br></br>
          <h2>Your Choices and Rights</h2>
          <br></br>
          <p className="para">
            You have certain rights regarding your personal information. These
            rights may include access, correction, deletion, or restrictions on
            how your data is processed. To exercise these rights or to ask any
            questions about your data, please contact us using the information
            provided below.
          </p>
          <br></br>
          <h3>Updates to this Privacy Policy</h3>
          <br></br>
          <p className="para">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on the Site with the updated effective date. Please
            review the Privacy Policy periodically to stay informed about how we
            are protecting your information.
          </p>
          <br></br>
          <h3>Contact Information</h3>
          <br></br>
          <p className="para">
            If you have any questions, concerns, or requests regarding this
            Privacy Policy or your personal information, please contact us at:{" "}
            <br></br>
            <a href="mailto:info@aigptzero.com">info@aigptzero.com</a> <br></br> By using AIGptzero and its Services,
            you agree to the terms and practices outlined in this Privacy
            Policy.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Privacy;
