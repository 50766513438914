import React, { useEffect, useState } from "react";
import axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";
import { Toaster, toast } from "sonner";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import GradientSVG from "../GradientSVG";
import "./Engine.css";
import { Link } from "react-router-dom";

function TextAnalysisComponent() {
  const idCSS = "hello";
  const [inputText, setInputText] = useState("");
  const [analysisResult, setAnalysisResult] = useState("");
  const [generationLabel, setGenerationLabel] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [characterCount, setCharacterCount] = useState(0);
  const maxCharacterLimit = 20000;
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scorePercentage, setScorePercentage] = useState(0);
  const [score, setScore] = useState(0);
  const [scoreData, setScoreData] = useState([]);
  const [analyzedText, setAnalyzedText] = useState("");

  function updateCounters(text) {
    const words = text.trim() ? text.trim().split(/\s+/) : [];
    const wordCount = words.length;
    const characterCount = text.length;
    setWordCount(wordCount);
    setCharacterCount(characterCount);
  }

  async function run(text) {
    setScoreData([]);
    let newAnalyzedText = inputText;
    try {
      const response = await axios.post(
        "https://api.sapling.ai/api/v1/aidetect",
        {
          key: "NJ2DS5UCZBOHPIM9ZSQLV621J3D4CAZ1",
          text,
          mode: "dev",
        }
      );
      const { status, data } = response;
      setScoreData(data.sentence_scores);
      // console.log("HI", setScoreData);
      setAnalysisResult(JSON.stringify(data, null, 4));
      // console.log(data.score);
      const scorePercentage = parseFloat(data.score) * 100;
      const score = data.score
      setScore(score)
      ///////
      newAnalyzedText = newAnalyzedText.replace(/\n/g, "<br/>");

      // scoreData.forEach((word) => {
      //   if (word.score > 0.05) {
      //     newAnalyzedText = newAnalyzedText.replace(
      //       new RegExp(word.sentence, "g"),
      //       `<span class="red-text">${word.sentence}</span>`
      //     );
      //   }
      // });

      // setAnalyzedText(newAnalyzedText);
      //////

      if (data.score <= 0.1) {
        setGenerationLabel("Your Text is Human Written");
      } else if (data.score > 0.1 && data.score < 0.2) {
        setGenerationLabel(
          "Your text is Likely Human written, may include parts generated by AI/GPT"
        );
      } else if (data.score >= 0.2 && data.score <= 0.4) {
        setGenerationLabel(
          "Your text is Mostly Like Human written, may include parts generated by AI/GPT"
      );
      } else if (data.score >= 0.6) {
        setGenerationLabel("Your Text is AI-generated");
      }
      setScorePercentage(scorePercentage.toFixed(2));
    } catch (err) {
      const { msg } = err.response.data;
      setAnalysisResult(`Error: ${msg}`);
      setGenerationLabel("Error");
    } finally {
      setLoading(false);
      setShowResult(true);
    }
    
  }

  useEffect(() => {
   
    let newAnalyzedText = inputText;
    newAnalyzedText = newAnalyzedText.replace(/\n/g, "<br/>");
    scoreData.forEach((word) => {
      if (score >= 0.1){
      if (word.score > 0.05) {
        newAnalyzedText = newAnalyzedText.replace(
          new RegExp(word.sentence, "g"),
          `<span class="red-text">${word.sentence}</span>`
        );
      }
    }});
    setAnalyzedText(newAnalyzedText);
  }, [scoreData, inputText]);

  const handleAnalysis = () => {
    if (inputText.trim() === "") {
      return toast.error("Error: Text cannot be empty.");
    } else if (wordCount < 5) {
      setGenerationLabel("Please input more text for a more accurate result");
      setShowResult(true);
    } else {
      run(inputText);
      setLoading(true);
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= maxCharacterLimit) {
      setInputText(newText);
      updateCounters(newText);
    }
  };
  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (pastedText.length + inputText.length > maxCharacterLimit) {
      e.preventDefault();
      const remainingChars = maxCharacterLimit - inputText.length;
      const truncatedText = pastedText.slice(0, remainingChars);
      setInputText(inputText + truncatedText);
      updateCounters(inputText + truncatedText);
    }
  };
  const textAreaStyles = {
    border: "none",
    outline: "none",
    resize: "none",
    width: "100%",
    fontFamily: "Changa, sans-serif",
    fontSize: "14px",
  };
  // console.log("progressPercentage", scorePercentage);
  return (
    <div className="container">
      <GradientSVG />
      <Toaster richColors position="top-right" />
      <div className="First">
        Trusted GPT-4, ChatGPT and AI Detector tool by AIGPTZero
      </div>
      <div className="Second">
        AIGPTZero the most Advanced and Reliable Chat GPT, GPT4 & AI Content
        Detector
      </div>
      <div className="card">
        <textarea
          value={inputText}
          onChange={handleTextChange}
          onPaste={handlePaste}
          placeholder="Enter text for analysis..."
          rows="5"
          cols="50"
          style={textAreaStyles}
        />
        <div className="secondSection">
          <div>&nbsp;</div>
          <div className="buttonContainer">
            <button onClick={handleAnalysis}>Analyze</button>
          </div>
          <div className="count-label">
            <p className="wordCount">{wordCount} Word Count</p>
            <p className="wordCount">
              {characterCount} / {maxCharacterLimit} Characters
            </p>
          </div>
        </div>
      </div>
      {showResult && !loading && (
        <div className="result-section">
          <p className="result-label">{generationLabel}</p>
          <div className="precentage">
            <CircularProgressbarWithChildren
              strokeWidth={15}
              value={scorePercentage}
              circleRatio={0.5}
              styles={{
                path: {
                  strokeLinecap: "butt",
                  stroke: `url(#${idCSS})`,
                  height: "100%",
                  transform: "rotate(-90deg)",
                  transformOrigin: "center center",
                },
                trail: {
                  strokeLinecap: "butt",
                  stroke: "#2e2e2e",
                  transform: "rotate(-90deg)",
                  transformOrigin: "center center",
                },
              }}
            >
              <div
                style={{
                  fontSize: 30,
                  marginTop: -90,
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {scorePercentage}% <br></br> AI GPT
              </div>
            </CircularProgressbarWithChildren>
          </div>
          <div
            className="analyzed-card"
            dangerouslySetInnerHTML={{ __html: analyzedText }}
          />
          <div className="analysis-result"></div>
          <div className="resultfootercard">
            {" "}
            <div className="red-box"></div> Highlighted text is generated by AI GPT{" "}
          </div>
        </div>
      )}
      {loading && (
        <div className="loading">
          <BounceLoader
            color={"#36d7b7"}
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />{" "}
        </div>
      )}
    </div>
  );
}

export default TextAnalysisComponent;
